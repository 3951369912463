<!--
 * @Author: lbh
 * @Date: 2022-12-24 16:11:35
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 16:25:10
 * @Description: marketingToolGraphicText3
-->
<template>
  <div
    class="marketingToolGraphicText1-box px-marketingToolGraphicText1-box"
    :class="`edit_${Xindex}_box `"
    @click="clickItem('box')"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="sub-title"
      v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
    ></h2>

    <div class="items">
      <div
        class="item"
        v-for="(item,index) in configs.items"
        :key="index"
        :class="`${(index%4 == 0  || (index-1)%4 ==0 ) ? `left` : `right`}`"
      >
        <div class="item_">
          <div
            class="index"
            :class="{s_2:configs.status == 2}"
            v-text="(index < 9 ? `0${index+1}` : index+1)"
          ></div>
          <div class="t-box">
            <span v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"></span>
            <img
              class="icon"
              :src="item.icon"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          subTitle: '',
          status: 1,
          items: []
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.marketingToolGraphicText1-box {
  display: inline-block;
  padding: 60px 0 90px;
  width: 100%;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .sub-title {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
  .items {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    .item {
      display: flex;
      margin-top: 50px;
      // margin-left: 150px;
      width: calc(50% - 75px);
      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-top: 0;
      }
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
      &:nth-child(2n + 1) {
        margin-right: auto;
        .item_ {
          margin-left: auto;
        }
      }
      &.left {
        .item_ {
          transform: translateX(-100px);
        }
      }
      &.right {
        .item_ {
          transform: translateX(100px);
        }
      }
      .item_ {
        display: flex;
        justify-content: center;
        .index {
          width: 97px;
          height: 97px;
          border: 8px solid #e97354;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: var(--themeColor);
          &.s_2 {
            border-color: var(--themeColor);
            background-color: var(--themeColor);
            color: #fff;
          }
        }
        .t-box {
          background: #ffffff;
          box-shadow: 0px 2px 6px 0px rgba(190, 28, 66, 0.15);
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          align-self: center;
          padding: 16px 50px 16px 22px;
          margin-left: -24px;
          span {
            font-size: 22px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .icon {
            position: absolute;
            width: 44px;
            top: -20px;
            right: -10px;
            // height: 44px;
          }
        }
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-marketingToolGraphicText1-box {
    .title {
      font-size: 16px !important;
    }
    .sub-title {
      font-size: 12px !important;
    }
    .item_ {
      .index {
        font-size: 12px !important;
      }
      .t-box {
        span {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media screen and(max-width:500px) {
  .px-marketingToolGraphicText1-box {
    padding: 25px 0;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .sub-title {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .items {
      margin-top: 27px;
      .item {
        width: calc(50% - 7.5px);
        margin-top: 30px;
        .item_ {
          .index {
            border-width: 3px;
            font-size: 13px !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: var(--themeColor);
            width: 32px;
            min-width: 32px;
            height: 32px;
          }
          .t-box {
            box-shadow: 0px 2px 6px 0px rgba(190, 28, 66, 0.15);
            margin-left: -8px;
            padding: 5px 12px 5px 12px;
            border-radius: 20px;
            span {
              font-size: 10px !important;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .icon {
              width: 14px;
            }
          }
        }
      }
    }
  }
}
</style>